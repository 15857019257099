module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ed6712","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"4BL","short_name":"4BL","description":"We optimize logistics to boost business productivity and simplify customers’ lives.","start_url":"/","lang":"en","background_color":"#fff","theme_color":"#004EC3","display":"standalone","icon":"src/img/Just-Logo.png","icon_options":{"purpose":"maskable"},"crossOrigin":"anonymous","localize":[{"start_url":"/ka/","lang":"ka","name":"4BL","short_name":"4BL","description":"We optimize logistics to boost business productivity and simplify customers’ lives."}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8e575613b02aa93ba9961137b148d5a3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://4bl.ge"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
